/* !
 * Adapted from hexo-filter-mathjax 0.5.3
 * Licensed MIT (c) 2019-2020 Mimi <stevenjoezhang@gmail.com> (https://zhangshuqiao.org)
 */

mjx-container[jax="SVG"] {
    direction: ltr;
}

mjx-container[jax="SVG"] > svg {
    overflow: visible;
}

mjx-container[jax="SVG"] > svg a {
    fill: blue;
    stroke: blue;
}

mjx-container[jax="SVG"][display="true"] {
    display: block;
    text-align: center;
    margin: 1em 0;
}

mjx-container[jax="SVG"][justify="left"] {
    text-align: left;
}

mjx-container[jax="SVG"][justify="right"] {
    text-align: right;
}

g[data-mml-node="merror"] > g {
    fill: red;
    stroke: red;
}

g[data-mml-node="merror"] > rect[data-background] {
    fill: yellow;
    stroke: none;
}

g[data-mml-node="mtable"] > line[data-line] {
    stroke-width: 70px;
    fill: none;
}

g[data-mml-node="mtable"] > rect[data-frame] {
    stroke-width: 70px;
    fill: none;
}

g[data-mml-node="mtable"] > .mjx-dashed {
    stroke-dasharray: 140;
}

g[data-mml-node="mtable"] > .mjx-dotted {
    stroke-linecap: round;
    stroke-dasharray: 0,140;
}

g[data-mml-node="mtable"] > svg {
    overflow: visible;
}

[jax="SVG"] mjx-tool {
    display: inline-block;
    position: relative;
    width: 0;
    height: 0;
}

[jax="SVG"] mjx-tool > mjx-tip {
    position: absolute;
    top: 0;
    left: 0;
}

mjx-tool > mjx-tip {
    display: inline-block;
    padding: .2em;
    border: 1px solid #888;
    font-size: 70%;
    background-color: #F8F8F8;
    color: black;
    box-shadow: 2px 2px 5px #AAAAAA;
}

g[data-mml-node="maction"][data-toggle] {
    cursor: pointer;
}

mjx-status {
    display: block;
    position: fixed;
    left: 1em;
    bottom: 1em;
    min-width: 25%;
    padding: .2em .4em;
    border: 1px solid #888;
    font-size: 90%;
    background-color: #F8F8F8;
    color: black;
}

foreignObject[data-mjx-xml] {
    font-family: initial;
    line-height: normal;
    overflow: visible;
}

.MathJax path {
    stroke-width: 3;
}

mjx-container[display="true"] {
    overflow: auto hidden;
}

mjx-container[display="true"] + br {
    display: none;
}